var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "house-settlement"
  }, [_c('filter-house', {
    attrs: {
      "estateId": _vm.estateId
    },
    on: {
      "setQueryData": _vm.setQueryData
    }
  }), _c('el-table', {
    staticClass: "mt15",
    attrs: {
      "border": "",
      "data": _vm.list,
      "show-summary": "",
      "summary-method": _vm.getSummaries,
      "max-height": _vm.maxHeight
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "status",
      "label": "状态",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('span', {
          class: _vm.salesStatusMaps[row.status].color
        }, [_vm._v(" " + _vm._s(_vm.salesStatusMaps[row.status].label) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "number",
      "label": "编号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.showDetail(row);
            }
          }
        }, [_vm._v(_vm._s(row.number))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "batch",
      "label": "批次"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.batch || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "massif",
      "label": "地块"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.massif || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "building",
      "label": "楼栋"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.building || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "unit",
      "label": "单元"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.unit || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "floor",
      "label": "楼层"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.floor || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "roomNumber",
      "label": "房号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.roomNumber || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "buildingSurface",
      "label": "建面"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(row.buildingSurface || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "recordPrice",
      "label": "备案总价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.recordPrice)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "paid",
      "label": "已付金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [row.paid ? _c('span', {
          staticClass: "orange"
        }, [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.paid)) + " ")]) : _c('span', [_vm._v("0")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "unpaid",
      "label": "未付金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [row.unpaid ? _c('span', {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.unpaid)) + " ")]) : _c('span', [_vm._v("0")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "settlementRatio",
      "label": "结清日期",
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [row.unpaid > 0 ? _c('span', {
          staticClass: "grey"
        }, [_vm._v(_vm._s(row.settlementRatio))]) : _c('span', [_vm._v(_vm._s(row.settleDate ? _vm.$time(row.settleDate).format("YYYY-MM-DD") : "-"))])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "footer-bar mt10"
  }, [_c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1), _vm.houseInfo ? _c('detail', {
    attrs: {
      "visible": _vm.showDetailDialog,
      "houseInfo": _vm.houseInfo,
      "houseId": _vm.houseId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showDetailDialog = $event;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }