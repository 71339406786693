<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" width="960px">
    <div class="settlement-info">
      <div class="settlement-header">
        <div class="title">
          <strong>房源编号 {{ houseInfo.number }}</strong>
        </div>
      </div>
      <el-table :data="[houseInfo]" class="mt20">
        <el-table-column prop="status" label="状态" width="55">
          <template v-slot="{ row }">
            <span :class="salesStatusMaps[row.status].color">
              {{ salesStatusMaps[row.status].label }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="batch" label="批次" width="70">
          <template v-slot="{ row }">
            {{ row.batch || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="massif" label="地块" width="70">
          <template v-slot="{ row }">
            {{ row.massif || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="building" label="楼栋" width="55">
          <template v-slot="{ row }">
            {{ row.building || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单元" width="55">
          <template v-slot="{ row }">
            {{ row.unit || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="floor" label="楼层" width="55">
          <template v-slot="{ row }">
            {{ row.floor || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="roomNumber" label="房号" width="55">
          <template v-slot="{ row }">
            {{ row.roomNumber || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="buildingSurface" label="建面" width="70">
          <template v-slot="{ row }">
            {{ row.buildingSurface || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="recordPrice" label="备案总价">
          <template v-slot="{ row }">
            {{ row.recordPrice | toThousands }}
          </template>
        </el-table-column>
        <el-table-column prop="paid" label="已付金额">
          <template v-slot="{ row }">
            <span class="orange" v-if="row.paid">
              {{ row.paid | toThousands }}
            </span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="unpaid" label="未付金额">
          <template v-slot="{ row }">
            <span class="red" v-if="row.unpaid">
              {{ row.unpaid | toThousands }}
            </span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="settlementRatio" label="结清日期" width="100">
          <template v-slot="{ row }">
            <span v-if="row.unpaid > 0" class="grey">{{
              row.settlementRatio
            }}</span>
            <span v-else>{{
              row.settleDate ? $time(row.settleDate).format("YYYY-MM-DD") : "-"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--header-mol-->
      <div class="header-mol mt30">
        <div class="info">
          <div class="title">结款记录</div>
        </div>
      </div>
      <!--header-mol end-->
      <el-table :data="list" class="mt24">
        <el-table-column prop="type" label="类型" width="60">
          <template v-slot="{ row }">
            {{ row.typeStr }}
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" label="付款单号" width="150">
        </el-table-column>
        <el-table-column prop="" label="付款金额">
          <template v-slot="{ row }">
            <span class="orange">{{ row.amount | toThousands }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentDate" label="付款日期"> </el-table-column>
        <el-table-column label="收/付款方">
          <template v-slot="{ row }">
            {{ row.type == 0 ? row.payerTypeStr : row.payeeTypeStr }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注说明">
          <template v-slot="{ row }">
            {{ row.remark || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="状态">
          <template v-slot="{ row }">
            <span class="red" v-if="row.orderState == 0">待确认</span>
            <span class="green" v-if="row.orderState == 1">已确认</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="tip mt10">
        注：付款方为“客户”的付款单，来自于“签约”订单，相关操作请前往相应签约单进行处理；
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { houseSettlementList } from "@/api/settlementOrder";
export default {
  props: {
    houseInfo: {
      type: Object,
      default: () => ({})
    },
    houseId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      salesStatusMaps: {
        2: {
          label: "在售",
          color: ""
        },
        3: {
          label: "认购",
          color: "orange"
        },
        4: {
          label: "签约",
          color: "red"
        },
        5: {
          label: "锁定",
          color: "grey"
        }
      },
      list: []
    };
  },
  watch: {
    houseId: {
      immediate: true,
      async handler(houseId) {
        if (houseId) {
          // 结款记录
          const res = await houseSettlementList(houseId);
          if (res) {
            this.list = res;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 35px 30px 35px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
::v-deep .el-dialog__headerbtn {
  font-size: 28px;
}
::v-deep .el-table {
  border: 1px solid #ebeef5;
  border-bottom: none;
}
.orange {
  color: $orange;
}
.grey {
  color: $color9;
}
.header-mol .info .title {
  padding-left: 15px;
  &:before {
    left: 0;
  }
}
.settlement-header {
  .title {
    strong {
      font-size: 18px;
      color: $color3;
      margin-right: 20px;
    }
  }
}
.tip {
  color: $color9;
}
</style>
