var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "distribution-detail"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.settlementList
    }
  }, [_c('div', {
    staticClass: "empty",
    attrs: {
      "slot": "empty"
    },
    slot: "empty"
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  }), _vm._v("请添加需要结款的房源 ")]), _c('el-table-column', {
    attrs: {
      "prop": "number",
      "label": "房源编号",
      "width": "70"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "batch",
      "label": "批次",
      "width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(row.batch || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "massif",
      "label": "地块",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.massif || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "building",
      "label": "楼栋",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.building || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "unit",
      "label": "单元",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.unit || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "floor",
      "label": "楼层",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.floor || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "roomNumber",
      "label": "房号",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.roomNumber || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "recordPrice",
      "label": "备案总价",
      "width": "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.recordPrice)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "未付金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c('span', {
          staticClass: "red"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.unpaid)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "",
      "width": "205"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref9) {
        var $index = _ref9.$index;
        return [_vm._v(" 结算金额（"), _c('el-popover', {
          ref: "popover-".concat($index),
          attrs: {
            "placement": "bottom",
            "width": "198",
            "trigger": "click"
          },
          model: {
            value: _vm.showBatchLayer,
            callback: function callback($$v) {
              _vm.showBatchLayer = $$v;
            },
            expression: "showBatchLayer"
          }
        }, [_c('div', {
          staticClass: "batch-setting"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('span', [_vm._v("批量设置")]), _c('el-checkbox', {
          model: {
            value: _vm.averageChecked,
            callback: function callback($$v) {
              _vm.averageChecked = $$v;
            },
            expression: "averageChecked"
          }
        }, [_vm._v("平均分配")])], 1), _c('el-input', {
          attrs: {
            "placeholder": "请输入",
            "size": "small",
            "clearable": ""
          },
          model: {
            value: _vm.batchAmount,
            callback: function callback($$v) {
              _vm.batchAmount = _vm._n($$v);
            },
            expression: "batchAmount"
          }
        }, [_c('template', {
          slot: "append"
        }, [_c('strong', [_vm._v("元")])])], 2), _c('div', {
          staticClass: "footer"
        }, [_c('el-button', {
          staticClass: "btn-cancel",
          attrs: {
            "size": "mini",
            "type": "info"
          },
          on: {
            "click": function click($event) {
              _vm.showBatchLayer = false;
            }
          }
        }, [_vm._v("取消")]), _c('el-button', {
          attrs: {
            "type": "primary",
            "size": "mini",
            "disabled": _vm.batchAmount == ''
          },
          on: {
            "click": _vm.submitBatchSet
          }
        }, [_vm._v("确定")])], 1)], 1), _c('el-link', {
          attrs: {
            "slot": "reference",
            "type": "primary"
          },
          slot: "reference"
        }, [_vm._v("批量设置")])], 1), _vm._v("） ")];
      }
    }, {
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c('el-input', {
          staticClass: "orange",
          attrs: {
            "placeholder": "请输入",
            "size": "small"
          },
          model: {
            value: row.amount,
            callback: function callback($$v) {
              _vm.$set(row, "amount", _vm._n($$v));
            },
            expression: "row.amount"
          }
        }, [_c('template', {
          slot: "append"
        }, [_c('strong', [_vm._v("元")])])], 2)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "table-summary"
  }, [_c('span', [_vm._v(" 待分配金额："), _c('strong', {
    class: {
      red: _vm.unAllocatedAmount < 0
    }
  }, [_vm._v("¥" + _vm._s(_vm.unAllocatedAmount))])]), _c('span', [_vm._v(" 已分配金额："), _c('strong', {
    staticClass: "orange"
  }, [_vm._v("¥" + _vm._s(_vm.allocatedAmount))])])]), _vm.unAllocatedAmount < 0 ? _c('div', {
    staticClass: "tip"
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  }), _vm._v("超额分配，请重新调整各房源的结算金额 ")]) : _vm._e(), _vm.unAllocatedAmount > 0 ? _c('div', {
    staticClass: "tip"
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  }), _vm._v("待分配金额＞0，请重新调整各房源的结算金额 ")]) : _vm._e(), _c('div', {
    staticClass: "action mt20"
  }, [_c('el-button', {
    staticClass: "btn-add",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function click($event) {
        _vm.showSelectHouseDailog = true;
      }
    }
  }, [_vm._v("+ 添加房源")])], 1), _c('select-house', {
    attrs: {
      "estateId": _vm.estateId,
      "houseIds": _vm.houseIds,
      "visible": _vm.showSelectHouseDailog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showSelectHouseDailog = $event;
      },
      "addHouse": _vm.addHouse
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }