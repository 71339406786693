<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    top="80px"
    width="906px"
    @open="openDialog"
  >
    <div class="settlement-info" v-if="info">
      <div class="settlement-header">
        <div class="title">
          <strong>结算单号 {{ info.orderNo }}</strong>
          <span class="status green" v-if="info.orderState == 1">已确认</span>
          <span class="status red" v-if="info.orderState == 0">待确认</span>
        </div>
        <ul>
          <li>
            <span class="label">付款金额：</span>
            <strong class="orange">{{ otherInfo.amount | toThousands }}</strong>
          </li>
          <li>
            <span class="label">付款日期：</span>
            <span>{{ info.paymentDate }}</span>
          </li>
          <li>
            <span class="label">付款方：</span>
            <span>{{ info.payerType == 0 ? "客户" : "我司" }}</span>
          </li>
          <li>
            <span class="label">付款方式：</span>
            <span>{{ paymentTypeMaps[otherInfo.paymentType] }}</span>
          </li>
          <li>
            <span class="label">交易单号：</span>
            <span>{{ otherInfo.paymentTransactionNo || "-" }}</span>
          </li>
          <li>
            <!-- <template v-if="info.payerType == 0 && info.payeeType == 2"> -->
            <!-- "客户" 付给 "开发商" 才显示"签约单号" -->
            <template v-if="info.subscriptionOrderNo">
              <span class="label">签约单号：</span>
              <el-link type="primary" @click="toOrderDetail">{{
                info.subscriptionOrderNo
              }}</el-link>
            </template>
          </li>
          <li class="remark" v-if="otherInfo.remark">
            <span class="label">备注说明：</span>
            <span v-html="otherInfo.remark.replace(/(\n|\r)+/g, '<br>')"></span>
          </li>
        </ul>
      </div>
      <!--header-mol-->
      <div class="header-mol mt40">
        <div class="info">
          <div class="title">
            结款明细（共{{
              info.settlementHouseDetailRespVos
                ? info.settlementHouseDetailRespVos.length
                : 0
            }}套房源）
          </div>
        </div>
      </div>
      <!--header-mol end-->
      <el-table :data="info.settlementHouseDetailRespVos" class="mt24">
        <el-table-column prop="number" label="房源编号"> </el-table-column>
        <el-table-column prop="batch" label="批次"> </el-table-column>
        <el-table-column prop="massif" label="地块"> </el-table-column>
        <el-table-column prop="building" label="楼栋"> </el-table-column>
        <el-table-column prop="unit" label="单元"> </el-table-column>
        <el-table-column prop="floor" label="楼层"> </el-table-column>
        <el-table-column prop="roomNumber" label="房号"> </el-table-column>
        <el-table-column prop="recordPrice" label="备案总价">
          <template v-slot="{ row }">
            {{ row.recordPrice | toThousands }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="结算金额">
          <template v-slot="{ row }">
            <span class="orange">{{ row.amount | toThousands }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="action mt15" v-if="info.payerType == 1">
        <el-button
          class="btn-edit"
          type="info"
          @click="showEditSettlementDialog = true"
          >编辑</el-button
        >
        <el-button class="btn-delete" type="info" @click="toVoid"
          >作废</el-button
        >
      </div>
      <!--header-mol-->
      <div class="header-mol mt40">
        <div class="info">
          <div class="title">操作记录</div>
        </div>
      </div>
      <!--header-mol end-->
      <el-table :data="info.systemLogRespVos" class="mt24">
        <el-table-column prop="inUserName" label="操作人" width="140">
        </el-table-column>
        <el-table-column prop="inDate" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="" label="备注">
          <template v-slot="{ row }">
            <p class="operated-content">
              <span class="label">{{ row.log.label }}</span>
              <template
                v-if="row.log.changeItemList && row.log.changeItemList.length"
                >：<span
                  v-html="
                    row.log.changeItemList.join(',').replace(
                      /^(https:\/\/.*(?:|jpeg|jpg|png|gif))$/,
                      `<a class='blue' href='$1' target='__black'>
                          <img src='$1' style='display: inline-block;width:50px;height:50px;object-fit:cover' />
                        </a>`
                    )
                  "
                ></span
              ></template>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <edit
      :visible.sync="showEditSettlementDialog"
      :estateId="estateId"
      :id="id"
      :info="statisticsInfo"
      @refresh="getSettlementInfo"
    ></edit>
  </el-dialog>
</template>

<script>
import Edit from "./edit";
import { settlementInfo, settlementDelete } from "@/api/settlementOrder";
export default {
  components: { Edit },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    id: {
      type: [String, Number],
      default: null
    },
    statisticsInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      paymentTypeMaps: {
        0: "银行转账",
        1: "POS机刷卡",
        2: "现金支付",
        3: "微信支付",
        4: "支付宝",
        5: "定金"
      },
      info: {},
      otherInfo: {},
      showEditSettlementDialog: false // 显示编辑付款弹层
    };
  },
  created() {},
  methods: {
    openDialog() {
      if (this.id) {
        this.getSettlementInfo();
      }
    },
    async getSettlementInfo() {
      const res = await settlementInfo(this.id);
      if (res) {
        this.info = res;
        this.otherInfo = res.settlementOrderDetailReqVos.length
          ? res.settlementOrderDetailReqVos[0]
          : {};
      }
    },
    toVoid() {
      this.$confirm("确认作废?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await settlementDelete(this.id);
          if (res) {
            this.$showSuccess("删除成功");
            this.getList();
            this.getStatistics();
          }
        })
        .catch(err => {});
    },
    // 进入订单详情
    toOrderDetail() {
      // 打开新浏览器tab
      this.$openWindow(
        `/distribution/appManage/signedOrder/${this.info.orderId}`
      );
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 35px 30px 35px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
::v-deep .el-dialog__headerbtn {
  font-size: 28px;
}
::v-deep .el-table {
  border: 1px solid #ebeef5;
  border-bottom: none;
}
.orange {
  color: $orange;
}
.header-mol .info .title {
  padding-left: 15px;
  &:before {
    left: 0;
  }
}
.settlement-header {
  .title {
    strong {
      font-size: 18px;
      color: $color3;
      margin-right: 20px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33%;
      font-size: 14px;
      color: $color3;
      margin-top: 20px;
      .label {
        color: $color9;
      }
      &.remark {
        width: 100%;
      }
    }
  }
}
.btn-edit {
  padding: 10px 25px;
  &.el-button--info {
    color: $primary;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
.btn-delete {
  padding: 10px 25px;
  &.el-button--info {
    color: $red;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
.operated-content {
  @include flex-yc;
  .label {
    white-space: nowrap;
  }
}
</style>
