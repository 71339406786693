import request from "@/utils/request";

// 结算概要
export const settlementStatistics = estateId =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/settlementStatistics/${estateId}`,
    method: "post"
  });

// 结算中心列表
export const settlementList = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/list`,
    data,
    method: "post"
  });

// 结算订单作废
export const settlementDelete = id =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/delete/${id}`,
    method: "post"
  });

// 结算详情
export const settlementInfo = id =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/info/${id}`,
    method: "post"
  });

// 创建结算付款订单
export const settlementCreate = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/create`,
    data,
    method: "post"
  });

// 编辑结算付款订单
export const settlementUpdate = data =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/update`,
    data,
    method: "post"
  });

// 查询房源的结算列表
export const houseSettlementList = houseId =>
  request({
    url: `/api/v1/platform/distribution/web/settlement/list/${houseId}`,
    method: "post"
  });
