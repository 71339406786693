<template>
  <div class="house-settlement">
    <filter-house
      :estateId="estateId"
      @setQueryData="setQueryData"
    ></filter-house>
    <el-table
      border
      :data="list"
      class="mt15"
      show-summary
      :summary-method="getSummaries"
      :max-height="maxHeight"
    >
      <el-table-column prop="status" label="状态" width="60">
        <template v-slot="{ row }">
          <span :class="salesStatusMaps[row.status].color">
            {{ salesStatusMaps[row.status].label }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="number" label="编号">
        <template v-slot="{ row }">
          <el-link type="primary" @click="showDetail(row)">{{
            row.number
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="batch" label="批次">
        <template v-slot="{ row }">
          {{ row.batch || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="massif" label="地块">
        <template v-slot="{ row }">
          {{ row.massif || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="building" label="楼栋">
        <template v-slot="{ row }">
          {{ row.building || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单元">
        <template v-slot="{ row }">
          {{ row.unit || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="floor" label="楼层">
        <template v-slot="{ row }">
          {{ row.floor || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="roomNumber" label="房号">
        <template v-slot="{ row }">
          {{ row.roomNumber || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="buildingSurface" label="建面">
        <template v-slot="{ row }">
          {{ row.buildingSurface || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="recordPrice" label="备案总价">
        <template v-slot="{ row }">
          {{ row.recordPrice | toThousands }}
        </template>
      </el-table-column>
      <el-table-column prop="paid" label="已付金额">
        <template v-slot="{ row }">
          <span class="orange" v-if="row.paid">
            {{ row.paid | toThousands }}
          </span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column prop="unpaid" label="未付金额">
        <template v-slot="{ row }">
          <span class="red" v-if="row.unpaid">
            {{ row.unpaid | toThousands }}
          </span>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column prop="settlementRatio" label="结清日期" width="100">
        <template v-slot="{ row }">
          <span v-if="row.unpaid > 0" class="grey">{{
            row.settlementRatio
          }}</span>
          <span v-else>{{
            row.settleDate ? $time(row.settleDate).format("YYYY-MM-DD") : "-"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer-bar mt10">
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
    <detail
      :visible.sync="showDetailDialog"
      v-if="houseInfo"
      :houseInfo="houseInfo"
      :houseId="houseId"
    ></detail>
  </div>
</template>

<script>
import FilterHouse from "../_components/filterHouse";
import Detail from "./_components/detail";
import { saleControlList, controlListCount } from "@/api/saleControl";
import { toThousands } from "@/utils/index";
export default {
  components: { Detail, FilterHouse },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      salesStatusMaps: {
        2: {
          label: "在售",
          color: ""
        },
        3: {
          label: "认购",
          color: "orange"
        },
        4: {
          label: "签约",
          color: "red"
        },
        5: {
          label: "锁定",
          color: "grey"
        }
      },
      queryData: {
        estateId: "", // 楼盘Id ,
        number: null, // 房源编号
        batch: null, //批次 ,
        building: null, //楼栋 ,
        massif: null, //地块 ,
        unit: null, //单元
        status: null, //状态: {2: 在售, 3: 认购, 4: 签约, 5: 锁定} = ['2', '3', '4', '5']stringEnum:"2", "3", "4", "5",
        pageSize: 30,
        pageNum: 1
      },
      list: [],
      total: 0,
      countInfo: null,
      showDetailDialog: false, // 显示详情弹窗
      maxHeight: 300,
      houseInfo: null, // 当前操作的房源信息
      houseId: null // 当前操作的房源id
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          this.getList();
        }
      }
    }
  },
  created() {
    // 给table一个最大高度，优化显示
    this.maxHeight = document.documentElement.clientHeight - 320;
  },
  methods: {
    toThousands,
    getList() {
      this.saleControlList(); // 房源列表
      this.controlListCount(); // 房源列表统计
    },
    async saleControlList() {
      // 房源列表
      const res = await saleControlList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    async controlListCount() {
      // 房源列表统计
      const res = await controlListCount(this.queryData);
      if (res) {
        this.countInfo = {
          recordPrice: res.recordPrice,
          paid: res.paid,
          unpaid: res.unpaid,
          settlementRatio: res.settlementRatio
        };
      }
    },
    setQueryData(params) {
      this.queryData = { ...this.queryData, ...params };
      this.queryData.pageNum = 1;
      this.getList();
    },
    getSummaries({ columns }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
        } else if (this.countInfo) {
          sums[index] =
            column.property == "settlementRatio"
              ? this.countInfo[column.property]
              : this.toThousands(this.countInfo[column.property]);
        }
      });
      return sums;
    },
    showDetail(row) {
      this.showDetailDialog = true;
      this.houseInfo = row;
      this.houseId = row.id;
    }
  }
};
</script>

<style scoped lang="scss">
.house-settlement {
  padding: 10px 0;
}
::v-deep .el-table__footer-wrapper tbody td {
  font-weight: bold;
  color: $color3;
}
::v-deep .el-input-group__append {
  padding: 0 10px;
}
.orange {
  color: $orange;
}
.grey {
  color: $color9;
}
.filter-box {
  display: flex;
  @include flex-ycb();
  .filter {
    display: flex;
    .el-select {
      width: 125px;
    }
  }
}
.footer-bar {
  @include flex-yce;
}
</style>
