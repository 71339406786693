<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    title="添加结算房源"
    width="1020px"
    top="80px"
    class="f-dialog edit-payment"
    :close-on-click-modal="false"
    @open="openDialog"
    append-to-body
  >
    <div class="house-settlement">
      <filter-house
        :estateId="estateId"
        :filterSettle="true"
        @setQueryData="setQueryData"
      ></filter-house>
      <div class="scroll-table mt15">
        <el-table
          v-loading="loading"
          :data="list"
          ref="multipleTable"
          :row-class-name="tableRowClassName"
          :row-key="getRowKey"
          @selection-change="selectChange"
          @select="selectRow"
          @row-click="rowClick"
        >
          <el-table-column
            type="selection"
            width="40"
            align="center"
            :reserve-selection="true"
            :selectable="checkSelectable"
          >
          </el-table-column>
          <el-table-column prop="status" label="状态" width="50">
            <template v-slot="{ row }">
              <span :class="salesStatusMaps[row.status].color">
                {{ salesStatusMaps[row.status].label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="编号" width="80">
          </el-table-column>
          <el-table-column prop="batch" label="批次" width="72">
            <template v-slot="{ row }">
              {{ row.batch || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="massif" label="地块" width="70">
            <template v-slot="{ row }">
              {{ row.massif || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="building" label="楼栋" width="70">
            <template v-slot="{ row }">
              {{ row.building || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="unit" label="单元" width="60">
            <template v-slot="{ row }">
              {{ row.unit || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="floor" label="楼层" width="50">
            <template v-slot="{ row }">
              {{ row.floor || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="roomNumber" label="房号" width="50">
            <template v-slot="{ row }">
              {{ row.roomNumber || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="buildingSurface" label="建面" width="60">
            <template v-slot="{ row }">
              {{ row.buildingSurface || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="buildingInsideSurface" label="套内" width="60">
            <template v-slot="{ row }">
              {{ row.buildingInsideSurface || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="recordPrice" label="备案总价">
            <template v-slot="{ row }">
              {{ row.recordPrice | toThousands }}
            </template>
          </el-table-column>
          <el-table-column prop="paid" label="已付金额">
            <template v-slot="{ row }">
              <span class="orange" v-if="row.paid">
                {{ row.paid | toThousands }}
              </span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="未付金额">
            <template v-slot="{ row }">
              <span class="red" v-if="row.unpaid">
                {{ row.unpaid | toThousands }}
              </span>
              <span v-else>0</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer-bar mt10">
        <el-checkbox
          v-model="isCheckAll"
          :indeterminate="indeterminate"
          @change="checkAll"
          >全选</el-checkbox
        >
        <f-pagination
          v-if="$attrs.visible"
          :page-num.sync="queryData.pageNum"
          :page-size.sync="queryData.pageSize"
          :total="total"
          :fixed="false"
          @change="saleControlList"
        ></f-pagination>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="sure">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saleControlList } from "@/api/saleControl";
import FilterHouse from "../../../../_components/filterHouse";
export default {
  components: { FilterHouse },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    houseIds: {
      // 已经在清单中的房源id
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      salesStatusMaps: {
        2: {
          label: "在售",
          color: ""
        },
        3: {
          label: "认购",
          color: "orange"
        },
        4: {
          label: "签约",
          color: "red"
        },
        5: {
          label: "锁定",
          color: "grey"
        }
      },
      queryData: {
        estateId: "", // 楼盘Id ,
        number: null, // 房源编号
        batch: null, //批次 ,
        building: null, //楼栋 ,
        massif: null, //地块 ,
        unit: null, //单元
        status: null, //状态: {2: 在售, 3: 认购, 4: 签约, 5: 锁定}
        pageSize: 30,
        pageNum: 1,
        settled: false // 筛选可结算房源
      },
      list: [],
      total: 0,
      isCheckAll: false,
      indeterminate: false,
      checkList: [],
      clear: [],
      loading: true
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler() {
        this.queryData.estateId = this.estateId;
      }
    }
  },
  methods: {
    openDialog() {
      if (this.queryData.estateId) {
        this.queryData.pageNum = 1;
        this.saleControlList();
      }
    },
    async saleControlList() {
      // 房源列表
      const res = await saleControlList({
        ...this.queryData,
        showLoading: false
      });
      if (res) {
        this.list = res.list;
        this.total = res.total;
        this.loading = false;
        this.$nextTick(() => {
          this.list.forEach(item => {
            // 勾选已经在清单中的房源，跨页的数据无法实现全部勾选，这里只保证，当前页的勾选状态
            // 最终合并数据，将在父组件中实现
            if (this.houseIds.includes(item.id)) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      }
    },
    setQueryData(params) {
      // 房源filter，参数回调
      this.queryData = { ...this.queryData, ...params };
      this.queryData.pageNum = 1;
      this.saleControlList();
    },
    checkSelectable(row) {
      // 未付金额 > 0
      return row.unpaid > 0;
    },
    rowClick(row) {
      // 未付金额 > 0
      if (row.unpaid > 0) {
        // 点击此行，触发checkbox选中事件
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    // 保存选中的数据id,row-key就是要指定一个key标识这一行的数据
    getRowKey(row) {
      return row.id;
    },
    checkAll(val) {
      // 自定义全选
      this.$refs.multipleTable.toggleAllSelection();
    },
    selectRow(selection, row) {
      // 存储取消选中(删除)的房源
      let isChecked = selection.map(item => item.id).includes(row.id);
      if (!isChecked) {
        this.clear.push(row.id);
      }
      this.clear = [...new Set(this.clear)];
    },
    selectChange(selection) {
      // 点击全选框，无法触发selectRow事件，全部取消选中时，需要在这里存储一次需要删除的房源
      if (selection.length == 0) {
        // selection为空，表示点了“全选返选”
        this.clear = this.list.map(item => item.id);
      }
      // 选中行发生变化时
      let checkAbleCount = this.list.filter(row => row.unpaid > 0).length; // 可选条数
      let checkedCount = selection.length; // 选中的条数

      // 设置自定义全选的选中状态
      if (checkedCount == checkAbleCount) {
        this.isCheckAll = true; // 全选
        this.indeterminate = false; // 半选
      } else {
        this.isCheckAll = false; // 全选
        this.indeterminate = Boolean(checkedCount); // 半选
      }

      // 获取选中的行
      this.checkList = selection;
    },
    tableRowClassName({ row }) {
      // 根据status,给行添加className
      if (this.checkList.map(item => item.id).includes(row.id)) {
        return "checked";
      }
    },
    cancel() {
      this.$refs.multipleTable.clearSelection();
      this.$emit("update:visible", false);
    },
    sure() {
      this.$emit("addHouse", {
        list: this.checkList, // 本次选中的房源
        clear: this.clear // 本次取消选中的房源
      });
      this.cancel();
    }
  }
};
</script>

<style scoped lang="scss">
.scroll-table {
  max-height: calc(100vh - 435px);
  overflow-y: auto;
}
::v-deep .el-table {
  tr {
    &.checked {
      background-color: #f5f8ff;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 35px 0px 35px;
}
.house-settlement {
  padding: 10px 0;
}
::v-deep .el-table__footer-wrapper tbody td {
  font-weight: bold;
  color: $color3;
}
::v-deep .el-input-group__append {
  padding: 0 10px;
}
.orange {
  color: $orange;
}
.grey {
  color: $color9;
}

.footer-bar {
  @include flex-ycb;
  padding-left: 18px;
}
</style>
