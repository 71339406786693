<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="id ? '编辑我司付款' : '创建我司付款'"
    top="80px"
    width="920px"
    class="f-dialog edit-payment"
    :close-on-click-modal="false"
    append-to-body
    @open="openDialog"
    @close="cancel"
  >
    <ul class="mt30">
      <li>
        <div class="title">未付金额</div>
        <p>
          <span class="red">¥ {{ info.unpaidTotalPrice | toThousands }}</span>
        </p>
      </li>
      <li>
        <div class="title">已付金额：</div>
        <p>
          <span class="orange">¥ {{ info.payedTotalPrice | toThousands }}</span>
        </p>
      </li>
      <li>
        <div class="title">应付金额：</div>
        <p>
          <span>¥ {{ info.recordTotalPrice | toThousands }}</span>
        </p>
      </li>
    </ul>
    <div class="header-mol mt30">
      <div class="info">
        <div class="title">付款信息</div>
      </div>
    </div>
    <el-form
      class="mt20"
      :model="formData"
      label-width="100px"
      size="small"
      ref="form"
    >
      <el-form-item label="收款单位：">
        <strong class="word-info">甲方（{{ estateName }}）</strong>
      </el-form-item>
      <el-form-item label="付款单位：">
        <strong class="word-info">我司</strong>
      </el-form-item>
      <el-form-item
        label="付款金额："
        prop="totalAmount"
        :rules="[$formRules.required()]"
      >
        <el-input
          v-model.number="formData.totalAmount"
          placeholder="请输入"
          class="orange"
        >
          <template slot="append"><strong>元</strong></template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="付款日期："
        prop="paymentDate"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          v-model="formData.paymentDate"
          type="date"
          placeholder="请选择"
          value-format="yyyy-MM-dd"
          style="width:100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="付款方式："
        prop="settlementOrderDetailReqVos[0].paymentType"
      >
        <el-radio-group
          v-model="formData.settlementOrderDetailReqVos[0].paymentType"
          @change="handlePayTypeSelect"
        >
          <el-radio-button
            v-for="item of payTypes"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="
          [0, 1].includes(formData.settlementOrderDetailReqVos[0].paymentType)
        "
        :label="
          formData.settlementOrderDetailReqVos[0].paymentType === 1
            ? 'POS机'
            : '收款银行'
        "
        prop="settlementOrderDetailReqVos[0].payWayId"
      >
        <el-select
          style="width: 100%"
          v-model="formData.settlementOrderDetailReqVos[0].payWayId"
          :placeholder="
            formData.settlementOrderDetailReqVos[0].paymentType === 1
              ? '请选择POS机'
              : '请选择收款银行'
          "
        >
          <el-option
            v-for="item of payOptions"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.settlementOrderDetailReqVos[0].paymentType === 0"
        label="付款户名"
        prop="payer"
      >
        <el-input
          v-model="formData.payer"
          placeholder="请输入收款方开户名"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="settlementOrderDetailReqVos[0].paymentTransactionNo"
        label="交易单号"
      >
        <el-input
          v-model="formData.settlementOrderDetailReqVos[0].paymentTransactionNo"
          placeholder="交易相关票据单号，如流水号、交易号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="相关附件"
        prop="settlementOrderDetailReqVos[0].attachList"
      >
        <f-upload
          class="mt20"
          size="small"
          :default-file-list="defaultFileList"
          @on-success="getUploadedImgs"
        ></f-upload>
      </el-form-item>
      <el-form-item
        prop="settlementOrderDetailReqVos[0].remark"
        label="备注说明："
      >
        <el-input
          type="textarea"
          v-model="formData.settlementOrderDetailReqVos[0].remark"
          placeholder="请输入"
          rows="3"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="header-mol mt40">
      <div class="info">
        <div class="title">结款明细</div>
      </div>
    </div>
    <settlement-detail
      ref="settlementDouseDetail"
      class="mt30"
      :estateId="estateId"
      :list="settlementList"
      :totalAmount="formData.totalAmount"
    ></settlement-detail>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
const formData = {
  payee: "甲方", //收款方的名称 ,
  payeeType: 2, // 收款方类型 1:公司 2：开发商(甲方)
  payer: "", // 付款方的名称 ,
  payerType: 1, //付款方类型 0:客户 1:公司
  totalAmount: null,
  paymentDate: "",
  settlementOrderDetailReqVos: [
    {
      amount: "", // 暂时 == totalAmout
      payWayId: null, // 收款POS机或者收款银行id
      paymentTransactionNo: "", // 交易单号
      paymentType: "", // 支付方式：0: 银行转账 1:POS机刷卡 2:现金支付 3:微信支付 4:支付宝 5:定金
      remark: "",
      attachList: [] // 附件
    }
  ],
  settlementHouseDetailRespVos: []
};
import {
  settlementInfo,
  settlementCreate,
  settlementUpdate
} from "@/api/settlementOrder";
import SettlementDetail from "./_components/settlementDetail";
import { getPayOptionsByPayWay } from "@/api/common";
import FUpload from "@/components/upload";
export default {
  components: { SettlementDetail, FUpload },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    estateName: {
      type: String,
      default: ""
    },
    id: {
      type: [String, Number],
      default: null
    },
    info: {
      // 结算概要
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: this.$deepClone(formData),
      payTypes: [
        { value: 0, label: "银行转账" },
        { value: 1, label: "POS机刷卡" },
        { value: 2, label: "现金支付" },
        { value: 3, label: "微信支付" },
        { value: 4, label: "支付宝" }
      ],
      defaultFileList: [],
      payOptions: [], // 收款银行 or 收款POS机
      showSelectHouseDailog: false,
      settlementList: [],
      houseIds: []
    };
  },
  methods: {
    openDialog() {
      this.formData.estateId = this.estateId;
      this.$nextTick(() => {
        if (this.id) {
          this.getSettlementInfo();
        } else {
          this.formData = this.$deepClone(formData);
          this.formData.paymentDate = this.$time().format("YYYY-MM-DD");
        }
      });
      // 如果编辑的时候默认是 银行或者 pos机再去请求相应数据 否则选择收款方式的时候再去获取
      if (
        [0, 1].includes(
          this.formData.settlementOrderDetailReqVos[0].paymentType
        )
      ) {
        this.getPayOptionsByPayWay();
      }
    },
    async getSettlementInfo() {
      // 结算明细
      const res = await settlementInfo(this.id);
      if (res) {
        Object.assign(this.formData, res);
        this.settlementList = res.settlementHouseDetailRespVos;
        this.defaultFileList =
          res.settlementOrderDetailReqVos[0].attachList?.map(
            item => item.fileUrl
          ) || [];
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
    },
    // 确认支付方式选择
    handlePayTypeSelect(value) {
      // 选择银行或者pos机
      if (value === 0 || value === 1) {
        this.formData.settlementOrderDetailReqVos[0].payWayId = "";
        this.getPayOptionsByPayWay();
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 获取银行或者pos机列表
    async getPayOptionsByPayWay() {
      const res = await getPayOptionsByPayWay({
        payMethod: this.formData.settlementOrderDetailReqVos[0].paymentType,
        estateId: this.estateId
      });
      if (res) {
        this.payOptions = res.map(item => {
          item.label = `${
            item.payMethod === 1
              ? item.name + "，" + "终端号" + item.clientNo + ""
              : item.name
          }`;
          item.description = `${item.name}，${
            item.payMethod === 1 ? item.mchName + "，" : ""
          }${item.mchNo}，${item.mchThridName}`;
          return item;
        });
      }
    },
    getUploadedImgs(fileList) {
      this.formData.settlementOrderDetailReqVos[0].attachList = fileList.map(
        file => ({ fileUrl: file.url })
      );
    },
    cancel() {
      this.$emit("update:visible", false);
      this.$refs.form.resetFields();
      this.settlementList = [];
      this.houseIds = [];
      this.defaultFileList = [];
    },
    submitForm() {
      // 验证表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        // 待分配金额
        let unAllocatedAmount = this.$refs.settlementDouseDetail
          .unAllocatedAmount;
        if (unAllocatedAmount > 0) {
          this.$showError("待分配金额＞0，请重新调整各房源的结算金额");
          return;
        } else if (unAllocatedAmount < 0) {
          this.$showError("超额分配，请重新调整各房源的结算金额");
          return;
        }
        this.formData.settlementOrderDetailReqVos[0].amount = this.formData.totalAmount;
        let params = {
          orderState: 1, // 通过结算中心创建的结算单， 状态默认为“已确认”；
          estateId: this.estateId,
          platformId: this.$store.state.platformInfo.platformId,
          id: this.formData.id ? this.formData.id : null,
          payee: this.formData.payee,
          payeeType: this.formData.payeeType,
          payer: this.formData.payer,
          payerType: this.formData.payerType,
          totalAmount: this.formData.totalAmount,
          paymentDate: this.formData.paymentDate,
          settlementOrderDetailReqVos: this.formData
            .settlementOrderDetailReqVos, // 收款详情
          settlementHouseDetailReqVos: this.$refs.settlementDouseDetail.getHouseData() // 房源分配详情
        };

        const fn = this.id ? settlementUpdate : settlementCreate;
        const res = await fn(params);
        if (res) {
          this.$showSuccess("操作成功");
          this.cancel();
          this.$emit("refresh");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 35px 30px 35px;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}
::v-deep .el-table {
  border: 1px solid #ebeef5;
  border-bottom: none;
}
.orange ::v-deep .el-input__inner {
  color: $orange;
  font-weight: bold;
}
.orange {
  color: $orange;
}
.edit-payment {
  ul {
    @include flex-yc;
    justify-content: space-around;
    height: 103px;
    background-color: $bdColor;
    border-radius: 4px;
    p {
      font-size: 18px;
      color: $color3;
      font-weight: bold;
      margin-top: 12px;
    }
  }
  .el-form {
    width: 498px;
    margin-left: -16px;
  }
  .word-info {
    color: $color3;
  }
  .header-mol .info .title {
    color: $color3;
    font-weight: bold;
  }
}
.btn-add {
  &.el-button--info {
    color: $primary;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
</style>
