<template>
  <div class="distribution-detail">
    <el-table :data="settlementList">
      <div slot="empty" class="empty">
        <i class="el-icon-warning-outline"></i>请添加需要结款的房源
      </div>
      <el-table-column prop="number" label="房源编号" width="70">
      </el-table-column>
      <el-table-column prop="batch" label="批次" width="70">
        <template v-slot="{ row }">
          {{ row.batch || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="massif" label="地块" width="60">
        <template v-slot="{ row }">
          {{ row.massif || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="building" label="楼栋" width="60">
        <template v-slot="{ row }">
          {{ row.building || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单元" width="60">
        <template v-slot="{ row }">
          {{ row.unit || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="floor" label="楼层" width="60">
        <template v-slot="{ row }">
          {{ row.floor || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="roomNumber" label="房号" width="60">
        <template v-slot="{ row }">
          {{ row.roomNumber || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="recordPrice" label="备案总价" width="110">
        <template v-slot="{ row }">
          {{ row.recordPrice | toThousands }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="未付金额">
        <template v-slot="{ row }">
          <span class="red">{{ row.unpaid | toThousands }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="" width="205">
        <template slot="header" slot-scope="{ $index }">
          结算金额（<el-popover
            placement="bottom"
            width="198"
            :ref="`popover-${$index}`"
            trigger="click"
            v-model="showBatchLayer"
          >
            <div class="batch-setting">
              <div class="title">
                <span>批量设置</span>
                <el-checkbox v-model="averageChecked">平均分配</el-checkbox>
              </div>
              <el-input
                v-model.number="batchAmount"
                placeholder="请输入"
                size="small"
                clearable
              >
                <template slot="append"><strong>元</strong></template>
              </el-input>
              <div class="footer">
                <el-button
                  size="mini"
                  class="btn-cancel"
                  type="info"
                  @click="showBatchLayer = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="batchAmount == ''"
                  @click="submitBatchSet"
                  >确定</el-button
                >
              </div>
            </div>
            <el-link type="primary" slot="reference"
              >批量设置</el-link
            > </el-popover
          >）
        </template>
        <template slot-scope="{ row }">
          <el-input
            v-model.number="row.amount"
            placeholder="请输入"
            size="small"
            class="orange"
          >
            <template slot="append"><strong>元</strong></template>
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-summary">
      <span>
        待分配金额：<strong :class="{ red: unAllocatedAmount < 0 }"
          >¥{{ unAllocatedAmount }}</strong
        >
      </span>
      <span>
        已分配金额：<strong class="orange">¥{{ allocatedAmount }}</strong>
      </span>
    </div>
    <div class="tip" v-if="unAllocatedAmount < 0">
      <i class="el-icon-warning-outline"></i
      >超额分配，请重新调整各房源的结算金额
    </div>
    <div class="tip" v-if="unAllocatedAmount > 0">
      <i class="el-icon-warning-outline"></i
      >待分配金额＞0，请重新调整各房源的结算金额
    </div>
    <div class="action mt20">
      <el-button
        class="btn-add"
        type="info"
        @click="showSelectHouseDailog = true"
        >+ 添加房源</el-button
      >
    </div>
    <select-house
      :estateId="estateId"
      :houseIds="houseIds"
      :visible.sync="showSelectHouseDailog"
      @addHouse="addHouse"
    ></select-house>
  </div>
</template>

<script>
import SelectHouse from "./selectHouse";
export default {
  components: { SelectHouse },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    list: {
      type: Array,
      default: () => []
    },
    totalAmount: {
      // 付款金额
      type: Number,
      default: null
    }
  },
  data() {
    return {
      search: "",
      averageChecked: false,
      batchAmount: "",
      showBatchLayer: false, // 显示批量设置popover
      showSelectHouseDailog: false, // 显示添加房源dailog
      settlementList: [],
      houseIds: []
    };
  },
  computed: {
    allocatedAmount() {
      // 已分配金额
      return this.settlementList.reduce((sum, cur) => {
        return this.$calc.accAdd(sum, cur.amount);
      }, 0);
    },
    unAllocatedAmount() {
      // 待分配金额
      return this.$calc.numSub(this.totalAmount, this.allocatedAmount);
    }
  },
  watch: {
    list: {
      deep: true,
      handler(list) {
        this.settlementList = this.$deepClone(list);
        this.houseIds = list.map(item => item.houseId);
      }
    },
    averageChecked: {
      // 平均分配，计算分配金额
      handler(val) {
        if (val) {
          if (!this.totalAmount) {
            this.$showError("请输入付款金额");
            return;
          }
          if (!this.settlementList.length) {
            this.$showError("请添加结算房源");
            return;
          }
          this.batchAmount = parseInt(
            this.$calc.division(this.totalAmount, this.settlementList.length)
          );
        }
      }
    },
    showBatchLayer: {
      // 批量设置popover隐藏的时候，重置相关信息
      handler(bool) {
        if (!bool) {
          this.batchAmount = ""; // 清空输入的金额
          this.averageChecked = false; // 重置"平均分配"checkbox
        }
      }
    }
  },
  methods: {
    addHouse({ list, clear }) {
      this.settlementList = [
        ...this.settlementList.filter(item => !clear.includes(item.houseId)), // 过滤删除的房源
        ...list
          .filter(item => !this.houseIds.includes(item.id)) // 已经在结算清单中的房源，不再做处理，保留原来输入的值
          .map(item => {
            return {
              ...item,
              houseId: item.id,
              amount: "", // 添加amount字段
              id: null, // 房源id与创建付款时的id冲突了，置为null
              orderId: null
            };
          })
      ];
      this.houseIds = this.settlementList.map(item => item.houseId);
    },
    submitBatchSet() {
      // 批量设置结算金额
      if (this.batchAmount) {
        if (!this.settlementList.length) {
          this.$showError("请添加结算房源");
          return;
        }
        this.settlementList = this.settlementList.map(item => {
          return {
            ...item,
            amount: this.batchAmount
          };
        });
      }
      this.showBatchLayer = false; // 关隐藏批量设置popover
    },
    getHouseData() {
      return this.settlementList.map(item => {
        return {
          amount: item.amount, // 交易金额
          houseId: item.houseId, // 房源id
          id: item.id,
          orderId: item.orderId // 结算订单id
        };
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input-group__append {
  padding: 0 10px;
  color: $color3;
}
.orange ::v-deep .el-input__inner {
  color: $orange;
  font-weight: bold;
}
.btn-add {
  &.el-button--info {
    color: $primary;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
.btn-cancel {
  &.el-button--info {
    color: $color3;
    background-color: #eee;
    border-color: #eee;
    &:active {
      background-color: #ddd;
    }
  }
}
.orange {
  color: $orange;
}
.table-summary {
  background-color: #f2f2f2;
  border: 1px solid #ebeef5;
  border-top: none;
  height: 40px;
  line-height: 40px;
  text-align: right;
  padding: 0 30px;
  span + span {
    margin-left: 30px;
  }
  strong {
    color: $color3;
    &.orange {
      color: $orange;
    }
    &.red {
      color: $red;
    }
  }
}
.tip {
  color: $red;
  margin-top: 15px;
  i {
    margin-right: 10px;
  }
}
.batch-setting {
  .title {
    @include flex-ycb;
    margin-bottom: 10px;
  }
  .footer {
    @include flex-ycb;
    margin-top: 12px;
    .el-button--mini {
      padding: 7px 25px;
    }
  }
}
.empty {
  @include flex-xyc;
  color: #c00;
  padding: 10px 0;
  i {
    font-size: 18px;
    margin-right: 10px;
  }
}
</style>
