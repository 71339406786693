var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "top": "80px",
      "width": "906px"
    },
    on: {
      "open": _vm.openDialog
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.info ? _c('div', {
    staticClass: "settlement-info"
  }, [_c('div', {
    staticClass: "settlement-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('strong', [_vm._v("结算单号 " + _vm._s(_vm.info.orderNo))]), _vm.info.orderState == 1 ? _c('span', {
    staticClass: "status green"
  }, [_vm._v("已确认")]) : _vm._e(), _vm.info.orderState == 0 ? _c('span', {
    staticClass: "status red"
  }, [_vm._v("待确认")]) : _vm._e()]), _c('ul', [_c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("付款金额：")]), _c('strong', {
    staticClass: "orange"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.otherInfo.amount)))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("付款日期：")]), _c('span', [_vm._v(_vm._s(_vm.info.paymentDate))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("付款方：")]), _c('span', [_vm._v(_vm._s(_vm.info.payerType == 0 ? "客户" : "我司"))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("付款方式：")]), _c('span', [_vm._v(_vm._s(_vm.paymentTypeMaps[_vm.otherInfo.paymentType]))])]), _c('li', [_c('span', {
    staticClass: "label"
  }, [_vm._v("交易单号：")]), _c('span', [_vm._v(_vm._s(_vm.otherInfo.paymentTransactionNo || "-"))])]), _c('li', [_vm.info.subscriptionOrderNo ? [_c('span', {
    staticClass: "label"
  }, [_vm._v("签约单号：")]), _c('el-link', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.toOrderDetail
    }
  }, [_vm._v(_vm._s(_vm.info.subscriptionOrderNo))])] : _vm._e()], 2), _vm.otherInfo.remark ? _c('li', {
    staticClass: "remark"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("备注说明：")]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.otherInfo.remark.replace(/(\n|\r)+/g, '<br>'))
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "header-mol mt40"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 结款明细（共" + _vm._s(_vm.info.settlementHouseDetailRespVos ? _vm.info.settlementHouseDetailRespVos.length : 0) + "套房源） ")])])]), _c('el-table', {
    staticClass: "mt24",
    attrs: {
      "data": _vm.info.settlementHouseDetailRespVos
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "number",
      "label": "房源编号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "batch",
      "label": "批次"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "massif",
      "label": "地块"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "building",
      "label": "楼栋"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "unit",
      "label": "单元"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "floor",
      "label": "楼层"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "roomNumber",
      "label": "房号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "recordPrice",
      "label": "备案总价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.recordPrice)) + " ")];
      }
    }], null, false, 3362333040)
  }), _c('el-table-column', {
    attrs: {
      "prop": "amount",
      "label": "结算金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c('span', {
          staticClass: "orange"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.amount)))])];
      }
    }], null, false, 164379793)
  })], 1), _vm.info.payerType == 1 ? _c('div', {
    staticClass: "action mt15"
  }, [_c('el-button', {
    staticClass: "btn-edit",
    attrs: {
      "type": "info"
    },
    on: {
      "click": function click($event) {
        _vm.showEditSettlementDialog = true;
      }
    }
  }, [_vm._v("编辑")]), _c('el-button', {
    staticClass: "btn-delete",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.toVoid
    }
  }, [_vm._v("作废")])], 1) : _vm._e(), _c('div', {
    staticClass: "header-mol mt40"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("操作记录")])])]), _c('el-table', {
    staticClass: "mt24",
    attrs: {
      "data": _vm.info.systemLogRespVos
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "inUserName",
      "label": "操作人",
      "width": "140"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "inDate",
      "label": "日期",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "备注"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c('p', {
          staticClass: "operated-content"
        }, [_c('span', {
          staticClass: "label"
        }, [_vm._v(_vm._s(row.log.label))]), row.log.changeItemList && row.log.changeItemList.length ? [_vm._v("："), _c('span', {
          domProps: {
            "innerHTML": _vm._s(row.log.changeItemList.join(',').replace(/^(https:\/\/.*(?:|jpeg|jpg|png|gif))$/, "<a class='blue' href='$1' target='__black'>\n                        <img src='$1' style='display: inline-block;width:50px;height:50px;object-fit:cover' />\n                      </a>"))
          }
        })] : _vm._e()], 2)];
      }
    }], null, false, 2879144901)
  })], 1)], 1) : _vm._e(), _c('edit', {
    attrs: {
      "visible": _vm.showEditSettlementDialog,
      "estateId": _vm.estateId,
      "id": _vm.id,
      "info": _vm.statisticsInfo
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showEditSettlementDialog = $event;
      },
      "refresh": _vm.getSettlementInfo
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }