<template>
  <div class="house-wrapper">
    <estate-sider
      class="mr20"
      @refresh="setEstateId"
      @getEstateName="setEstateName"
    ></estate-sider>
    <div class="content">
      <el-tabs type="card" class="fm-tabs-card" v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in tabs"
          :label="item.name"
          :name="`${index}`"
          :key="item.component"
        >
          <component
            :is="tabs[activeName].component"
            :estateId="estateId"
            :estateName="estateName"
          ></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Manage from "./_components/manage";
import House from "./_components/house";
import EstateSider from "../../_components/estateSider";
export default {
  components: {
    Manage,
    House,
    EstateSider
  },
  data() {
    return {
      tabs: [
        {
          name: "结算管理中心",
          component: "Manage"
        },
        {
          name: "房源结算明细",
          component: "House"
        }
      ],
      activeName: "0",
      estateId: null,
      estateName: ""
    };
  },
  created() {},
  methods: {
    setEstateId(estateId) {
      // 选择楼盘，查询楼盘下的相关订单信息
      this.estateId = estateId;
    },
    setEstateName(name) {
      this.estateName = name;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../_styles/house.scss";
::v-deep {
  .el-tabs__content {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>
