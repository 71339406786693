var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog edit-payment",
    attrs: {
      "title": _vm.id ? '编辑我司付款' : '创建我司付款',
      "top": "80px",
      "width": "920px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "open": _vm.openDialog,
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('ul', {
    staticClass: "mt30"
  }, [_c('li', [_c('div', {
    staticClass: "title"
  }, [_vm._v("未付金额")]), _c('p', [_c('span', {
    staticClass: "red"
  }, [_vm._v("¥ " + _vm._s(_vm._f("toThousands")(_vm.info.unpaidTotalPrice)))])])]), _c('li', [_c('div', {
    staticClass: "title"
  }, [_vm._v("已付金额：")]), _c('p', [_c('span', {
    staticClass: "orange"
  }, [_vm._v("¥ " + _vm._s(_vm._f("toThousands")(_vm.info.payedTotalPrice)))])])]), _c('li', [_c('div', {
    staticClass: "title"
  }, [_vm._v("应付金额：")]), _c('p', [_c('span', [_vm._v("¥ " + _vm._s(_vm._f("toThousands")(_vm.info.recordTotalPrice)))])])])]), _c('div', {
    staticClass: "header-mol mt30"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("付款信息")])])]), _c('el-form', {
    ref: "form",
    staticClass: "mt20",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "收款单位："
    }
  }, [_c('strong', {
    staticClass: "word-info"
  }, [_vm._v("甲方（" + _vm._s(_vm.estateName) + "）")])]), _c('el-form-item', {
    attrs: {
      "label": "付款单位："
    }
  }, [_c('strong', {
    staticClass: "word-info"
  }, [_vm._v("我司")])]), _c('el-form-item', {
    attrs: {
      "label": "付款金额：",
      "prop": "totalAmount",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "orange",
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.totalAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "totalAmount", _vm._n($$v));
      },
      expression: "formData.totalAmount"
    }
  }, [_c('template', {
    slot: "append"
  }, [_c('strong', [_vm._v("元")])])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "付款日期：",
      "prop": "paymentDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "placeholder": "请选择",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.formData.paymentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "paymentDate", $$v);
      },
      expression: "formData.paymentDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "付款方式：",
      "prop": "settlementOrderDetailReqVos[0].paymentType"
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.handlePayTypeSelect
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].paymentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "paymentType", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].paymentType"
    }
  }, _vm._l(_vm.payTypes, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), [0, 1].includes(_vm.formData.settlementOrderDetailReqVos[0].paymentType) ? _c('el-form-item', {
    attrs: {
      "label": _vm.formData.settlementOrderDetailReqVos[0].paymentType === 1 ? 'POS机' : '收款银行',
      "prop": "settlementOrderDetailReqVos[0].payWayId"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.formData.settlementOrderDetailReqVos[0].paymentType === 1 ? '请选择POS机' : '请选择收款银行'
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].payWayId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "payWayId", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].payWayId"
    }
  }, _vm._l(_vm.payOptions, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.label
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.settlementOrderDetailReqVos[0].paymentType === 0 ? _c('el-form-item', {
    attrs: {
      "label": "付款户名",
      "prop": "payer"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入收款方开户名"
    },
    model: {
      value: _vm.formData.payer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "payer", $$v);
      },
      expression: "formData.payer"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "prop": "settlementOrderDetailReqVos[0].paymentTransactionNo",
      "label": "交易单号"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "交易相关票据单号，如流水号、交易号"
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].paymentTransactionNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "paymentTransactionNo", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].paymentTransactionNo"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "相关附件",
      "prop": "settlementOrderDetailReqVos[0].attachList"
    }
  }, [_c('f-upload', {
    staticClass: "mt20",
    attrs: {
      "size": "small",
      "default-file-list": _vm.defaultFileList
    },
    on: {
      "on-success": _vm.getUploadedImgs
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "settlementOrderDetailReqVos[0].remark",
      "label": "备注说明："
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "请输入",
      "rows": "3"
    },
    model: {
      value: _vm.formData.settlementOrderDetailReqVos[0].remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.settlementOrderDetailReqVos[0], "remark", $$v);
      },
      expression: "formData.settlementOrderDetailReqVos[0].remark"
    }
  })], 1)], 1), _c('div', {
    staticClass: "header-mol mt40"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("结款明细")])])]), _c('settlement-detail', {
    ref: "settlementDouseDetail",
    staticClass: "mt30",
    attrs: {
      "estateId": _vm.estateId,
      "list": _vm.settlementList,
      "totalAmount": _vm.formData.totalAmount
    }
  }), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("提交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }