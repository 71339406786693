<template>
  <div class="manage">
    <!--header-mol-->
    <div class="header-mol">
      <div class="info">
        <div class="title">结算概要</div>
      </div>
    </div>
    <!--header-mol end-->
    <el-row :gutter="20" class="mt20">
      <el-col :span="8">
        <div class="outline-cell">
          <div class="title">合计应付金额<span> = ∑房源备案总价</span></div>
          <div class="price">
            <div class="num">
              ¥<strong>{{ info.recordTotalPrice | toThousands }}</strong>
            </div>
          </div>
          <div class="summary">
            <span>
              合计房源(套)<em>{{ info.houseTotal }}</em>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="outline-cell">
          <div class="title">合计已付金额<span> = ∑结算单</span></div>
          <div class="price">
            <div class="num orange">
              ¥<strong>{{ info.payedTotalPrice | toThousands }}</strong>
            </div>
            <div class="tip">
              <p>¥{{ info.unconfirmedPayedTotalPrice | toThousands }}</p>
              <p>含待确认</p>
            </div>
          </div>
          <div class="summary">
            <span>
              我司
              <em><i>￥</i>{{ info.companyPayedTotalPrice | toThousands }}</em>
            </span>
            <span>
              客户
              <em><i>￥</i>{{ info.customerPayedTotalPrice | toThousands }}</em>
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="outline-cell">
          <div class="title">
            合计未付金额<span> = 应付金额 - 已付金额</span>
          </div>
          <div class="price">
            <div class="num red">
              ¥<strong>{{ info.unpaidTotalPrice | toThousands }}</strong>
            </div>
          </div>
          <div class="summary">
            <span>
              未结清房源(套)<em>{{ info.unpaidHouseTotal }}</em>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--header-mol-->
    <div class="header-mol mt30">
      <div class="info">
        <div class="title">结算清单</div>
        <div class="summary">
          付款方为“客户”的结算单，来自于“签约”订单，相关操作请前往相应签约单进行处理；
        </div>
      </div>
    </div>
    <!--header-mol end-->
    <!--filter-box-->
    <div class="filter-box mt20">
      <div class="filter">
        <el-input
          placeholder="付款单号、交易单号"
          v-model="queryData.keyword"
          @keyup.enter.native="search"
          clearable
          style="width: 295px;"
          class="mr10 f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="queryData.type"
          placeholder="类型"
          style="width: 135px;"
          class="mr10"
          clearable
          @change="search"
        >
          <el-option :value="0" label="收款"></el-option>
          <el-option :value="1" label="退款"></el-option>
        </el-select>
        <el-select
          v-model="queryData.payerType"
          placeholder="付款方"
          style="width: 135px;"
          class="mr10"
          clearable
          @change="search"
          v-if="queryData.type === 0"
        >
          <el-option :value="0" label="客户"></el-option>
          <el-option :value="1" label="我司"></el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 240px;"
          @change="dateChange"
        >
        </el-date-picker>
      </div>
      <div class="action">
        <el-button type="primary" @click="showEditSettlement()"
          >创建付款结算</el-button
        >
        <el-button icon="iconfont icon-refresh" @click="refresh"></el-button>
      </div>
    </div>
    <!--filter-box end-->
    <el-table border :data="list" class="mt15">
      <el-table-column prop="orderState" label="状态" width="80">
        <template v-slot="{ row }">
          <span :class="orderStateMaps[row.orderState].color">
            {{ orderStateMaps[row.orderState].label }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="60">
        <template v-slot="{ row }">
          {{ row.type == 0 ? "收款" : "退款" }}
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="付款单号" width="160">
        <template v-slot="{ row }">
          <el-link type="primary" @click="showInfo(row.id)">{{
            row.orderNo
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="付款金额">
        <template v-slot="{ row }">
          <span class="orange">{{ row.totalAmount | toThousands }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="paymentDate" label="付款日期"> </el-table-column>
      <el-table-column
        prop="payerType"
        :label="
          queryData.type == null
            ? '收/付款方'
            : queryData.type == 0
            ? '付款方'
            : '收款方'
        "
      >
        <template v-slot="{ row }">
          {{ row.type == 0 ? row.payerTypeStr : row.payeeTypeStr }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="付款方式">
        <template v-slot="{ row }">
          {{ paymentTypeMaps[row.paymentType] }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentTransactionNo" label="交易单号">
        <template v-slot="{ row }">
          {{ row.paymentTransactionNo || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="160">
        <template v-slot="{ row }">
          <template v-if="row.payerType == 1">
            <el-link type="primary" @click="showEditSettlement(row.id)"
              >编辑</el-link
            >
            <el-link type="danger" @click="toVoid(row.id)">作废</el-link>
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer-bar mt10">
      <div class="action"></div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
    <detail
      :visible.sync="showInfoDialog"
      :estateId="estateId"
      :statisticsInfo="info"
      :id="curOrderId"
    ></detail>
    <edit
      :visible.sync="showEditSettlementDialog"
      :estateId="estateId"
      :estateName="estateName"
      :info="info"
      :id="curOrderId"
      @refresh="reset"
    ></edit>
  </div>
</template>

<script>
import {
  settlementStatistics,
  settlementList,
  settlementDelete
} from "@/api/settlementOrder";
import Detail from "./_components/detail";
import Edit from "./_components/edit";
export default {
  components: { Detail, Edit },
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    estateName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      date: "",
      paymentTypeMaps: {
        0: "银行转账",
        1: "POS机刷卡",
        2: "现金支付",
        3: "微信支付",
        4: "支付宝",
        5: "定金"
      },
      orderStateMaps: {
        0: {
          label: "待确认",
          color: "red"
        },
        1: {
          label: "已确认",
          color: "green"
        },
        2: {
          label: "转入房款",
          color: ""
        }
      },
      queryData: {
        estateId: null,
        keyword: "", // 付款单号、交易单号
        type: null,
        payStartTime: "",
        payEndTime: "",
        payerType: "", // 付款方类型 0:客户 1:公司
        //payeeType: "", // 收款方类型 1:公司 2：开发商(甲方)
        pageNum: 1,
        pageSize: 30
      },
      list: [],
      total: 0,
      info: {}, // 结算概要
      showInfoDialog: false, // 显示结算信息弹层
      showEditSettlementDialog: false, // 显示编辑付款弹层
      curOrderId: null
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          this.reset();
        }
      }
    },
    "queryData.type": {
      handler(type) {
        if (type !== 0) {
          // 类型为收款，才筛选付款方
          this.queryData.payerType = "";
        }
      }
    }
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    reset() {
      this.search();
      this.getStatistics();
    },
    refresh() {
      this.getList();
      this.getStatistics();
    },
    async getList() {
      // 结算清单
      const res = await settlementList(this.queryData);
      if (res) {
        const { list, total } = res;
        this.list = list;
        this.total = total;
      }
    },
    async getStatistics() {
      // 结算概要
      const res = await settlementStatistics(this.queryData.estateId);
      if (res) {
        this.info = res;
      }
    },
    dateChange(val) {
      // 日期
      let [start, end] = val || [];
      this.queryData.payStartTime = start;
      this.queryData.payEndTime = end;
      this.search();
    },
    toVoid(id) {
      this.$confirm("确认作废?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await settlementDelete(id);
          if (res) {
            this.$showSuccess("删除成功");
            this.reset();
          }
        })
        .catch(err => {});
    },
    showInfo(id) {
      this.curOrderId = id;
      this.showInfoDialog = true;
    },
    showEditSettlement(id) {
      this.showEditSettlementDialog = true;
      this.curOrderId = id;
    }
  }
};
</script>

<style scoped lang="scss">
.orange {
  color: $orange;
}
.manage {
  padding: 20px;
  .outline-cell {
    @include flex-yc2;
    background-color: $bgColor;
    border-radius: 4px;
    height: 137px;
    padding: 0 20px;
    .title {
      font-size: 13px;
      color: $color3;
      span {
        color: $color9;
      }
    }
    .price {
      @include flex-ycb;
      height: 32px;
      margin-top: 20px;
      color: $color3;
      .tip {
        p {
          font-size: 12px;
          color: $red;
          line-height: 140%;
        }
      }
    }
    .num {
      font-size: 16px;
      font-weight: bold;
      strong {
        font-size: 26px;
        margin-left: 5px;
      }
    }
    .summary {
      font-size: 13px;
      color: $color9;
      margin-top: 20px;
      em,
      i {
        font-style: normal;
      }
      em {
        font-size: 16px;
        color: $color3;
        margin-left: 5px;
      }
      i {
        font-size: 13px;
      }
      span + span {
        margin-left: 10px;
      }
    }
  }
}
.filter-box {
  display: flex;
  @include flex-ycb();
  .filter {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .action {
    display: flex;
  }
}
.el-link + .el-link {
  margin-left: 10px;
}
.footer-bar {
  @include flex-yce;
}
</style>
