var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "960px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "settlement-info"
  }, [_c('div', {
    staticClass: "settlement-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('strong', [_vm._v("房源编号 " + _vm._s(_vm.houseInfo.number))])])]), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "data": [_vm.houseInfo]
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "status",
      "label": "状态",
      "width": "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('span', {
          class: _vm.salesStatusMaps[row.status].color
        }, [_vm._v(" " + _vm._s(_vm.salesStatusMaps[row.status].label) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "batch",
      "label": "批次",
      "width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.batch || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "massif",
      "label": "地块",
      "width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.massif || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "building",
      "label": "楼栋",
      "width": "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.building || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "unit",
      "label": "单元",
      "width": "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.unit || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "floor",
      "label": "楼层",
      "width": "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(row.floor || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "roomNumber",
      "label": "房号",
      "width": "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v(" " + _vm._s(row.roomNumber || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "buildingSurface",
      "label": "建面",
      "width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v(" " + _vm._s(row.buildingSurface || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "recordPrice",
      "label": "备案总价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.recordPrice)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "paid",
      "label": "已付金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [row.paid ? _c('span', {
          staticClass: "orange"
        }, [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.paid)) + " ")]) : _c('span', [_vm._v("0")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "unpaid",
      "label": "未付金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [row.unpaid ? _c('span', {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(_vm._f("toThousands")(row.unpaid)) + " ")]) : _c('span', [_vm._v("0")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "settlementRatio",
      "label": "结清日期",
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [row.unpaid > 0 ? _c('span', {
          staticClass: "grey"
        }, [_vm._v(_vm._s(row.settlementRatio))]) : _c('span', [_vm._v(_vm._s(row.settleDate ? _vm.$time(row.settleDate).format("YYYY-MM-DD") : "-"))])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "header-mol mt30"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("结款记录")])])]), _c('el-table', {
    staticClass: "mt24",
    attrs: {
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "type",
      "label": "类型",
      "width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v(" " + _vm._s(row.typeStr) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "orderNo",
      "label": "付款单号",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "付款金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_c('span', {
          staticClass: "orange"
        }, [_vm._v(_vm._s(_vm._f("toThousands")(row.amount)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "paymentDate",
      "label": "付款日期"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "收/付款方"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_vm._v(" " + _vm._s(row.type == 0 ? row.payerTypeStr : row.payeeTypeStr) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "label": "备注说明"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_vm._v(" " + _vm._s(row.remark || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "",
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var row = _ref17.row;
        return [row.orderState == 0 ? _c('span', {
          staticClass: "red"
        }, [_vm._v("待确认")]) : _vm._e(), row.orderState == 1 ? _c('span', {
          staticClass: "green"
        }, [_vm._v("已确认")]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "tip mt10"
  }, [_vm._v(" 注：付款方为“客户”的付款单，来自于“签约”订单，相关操作请前往相应签约单进行处理； ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }