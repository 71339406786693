<template>
  <div class="filter-box">
    <div class="filter">
      <el-input
        placeholder="房源编号"
        v-model="queryData.number"
        style="width: 250px;"
        class="mr10 f-search-bar"
        clearable
        @clear="search"
        @keyup.enter.native="search"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        v-model="queryData.batch"
        placeholder="批次"
        clearable
        class="mr10"
        @change="search"
      >
        <el-option
          :value="item"
          :label="item"
          v-for="(item, index) in batchList"
          :key="index"
          @change="search"
        ></el-option>
      </el-select>
      <el-cascader
        :options="locationOptions"
        :props="{
          checkStrictly: true,
          value: 'name',
          label: 'name'
        }"
        v-model="location"
        placeholder="位置"
        clearable
        @change="locationChange"
        style="width:140px;"
        class="mr10"
      ></el-cascader>
      <el-select
        v-model="queryData.status"
        placeholder="状态"
        clearable
        class="mr20"
        @change="search"
      >
        <el-option
          :value="item.value"
          :label="item.label"
          v-for="(item, index) in salesStatus"
          :key="index"
        ></el-option>
      </el-select>
      <el-checkbox v-model="settled" @change="search" v-if="filterSettle"
        >可结算房源</el-checkbox
      >
    </div>
    <el-button icon="iconfont icon-refresh" @click="refresh"></el-button>
  </div>
</template>
<script>
import { filterCascade, saleControlFilterBatch } from "@/api/saleControl";
export default {
  props: {
    estateId: {
      type: [String, Number],
      default: null
    },
    filterSettle: {
      // 是否显示，筛选可结算房源checkbox
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      salesStatus: [
        {
          label: "在售",
          value: 2
        },
        {
          label: "认购",
          value: 3
        },
        {
          label: "签约",
          value: 4
        },
        {
          label: "锁定",
          value: 5
        }
      ],
      locationOptions: [], // 位置 地块-楼栋-单元级联数据
      location: "", // 级联选择显示的数据
      batchList: [], // 批次列表
      settled: false, // 筛选可结算房源
      queryData: {
        number: "", // 房源编号
        batch: null, //批次 ,
        building: null, //楼栋 ,
        massif: null, //地块 ,
        unit: null, //单元
        status: "" //状态: {2: 在售, 3: 认购, 4: 签约, 5: 锁定} = ['2', '3', '4', '5']stringEnum:"2", "3", "4", "5",
      }
    };
  },
  watch: {
    estateId: {
      immediate: true,
      handler(id) {
        // 楼盘id变化时，重新获取数据
        if (id) {
          this.queryData.estateId = id;
          this.locationList(); // 地块-楼栋-单元，级联选择，楼盘发生变化时，需重新查询
          this.getBatchList(); // 批次列表
        }
      }
    }
  },
  methods: {
    refresh() {
      this.$emit("setQueryData", this.queryData);
    },
    search() {
      if (this.filterSettle) {
        // 筛选可结算房源
        this.queryData.settled = this.settled;
      }
      ["batch", "building", "massif", "unit", "number", "status"].forEach(
        item => {
          if (this.queryData[item] == "") {
            this.queryData[item] = null;
          }
        }
      );
      this.$emit("setQueryData", this.queryData);
    },
    async getBatchList() {
      // 获取批次列表
      let params = {
        estateId: this.queryData.estateId
      };
      const res = await saleControlFilterBatch(params);
      if (res) {
        this.batchList = res;
      }
    },
    async locationList() {
      // 位置：地块-楼栋-单元，级联选择
      let params = {
        estateId: this.queryData.estateId
      };
      const res = await filterCascade(params);
      if (res) {
        const replaceEmptyChildren = data => {
          // 将children = []的children置为null
          data.forEach(item => {
            if (item.children instanceof Array && item.children.length > 0) {
              item.children = replaceEmptyChildren(item.children);
            } else {
              item.children = null;
            }
          });
          return data;
        };
        this.locationOptions = replaceEmptyChildren(res);
      }
    },
    locationChange(arr) {
      this.queryData.massif = arr[0] || null;
      this.queryData.building = arr[1] || null;
      this.queryData.unit = arr[2] || null;
      this.search();
    }
  }
};
</script>

<style scoped lang="scss">
.filter-box {
  @include flex-ycb();
  .filter {
    @include flex-ycb();
    .el-select {
      width: 125px;
    }
  }
}
</style>
